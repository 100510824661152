import CTA from 'components/UI/Cta';
import RichText from 'components/UI/Richtext';
import Image from 'components/UI/Image';
import { ReactElement } from 'react';
import { IComponentHeroInlineCircleFields } from 'types/contentful';

export default function HeroInlineCircle({
  heading,
  colorScheme,
  image,
  cta,
}: IComponentHeroInlineCircleFields): ReactElement {
  const [background, highlight] = colorScheme.split('-');

  return (
    <div
      className={`flex flex-col md:flex-row bg-${background} overflow-hidden ${
        !image ? 'pt-5 pb-5 md:pb-16' : ''
      }`}
    >
      <div className="flex-initial md:self-center">
        <RichText
          document={heading}
          highlight={highlight}
          classNames={`font-heading text-4xl md:text-5xl md:pl-10 xl:pl-24 pl-4 py-4 max-w-725 text-${background}`}
        />
        <div className="w-3/4 md:pl-24 lg:w-full text-5xl">
          {cta && <CTA {...cta.fields} classNames="mt-6" />}
        </div>
      </div>

      {image && (
        <div className="relative flex-auto">
          <svg
            viewBox="0 0 75 500"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`hidden md:block fill-current absolute left-0 text-${background}-actual h-full w-20`}
          >
            <ellipse cx="-448" cy="250.5" rx="523" ry="523.5" />
          </svg>
          <Image
            image={image}
            classNames={`w-full h-full object-cover`}
            query="fit=fill&f=center&h=500&w=800"
          />
        </div>
      )}
    </div>
  );
}
